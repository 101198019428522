/*
 * @Description:
 */
/*
* 路由对象模块
* */
import Vue from 'vue'
import VueRouter from 'vue-router'

/* 引入pages*/
const Tabbar = () => import('@/views/tabbar/tabbar')
const Navbar = () => import('@/views/navbar/navbar')

// 申明使用插件
Vue.use(VueRouter)
import config from '../../config.json'

// DONE: 默认加载路由, 无需权限
export const constantRouterMap = [

  {
    path: '/login',
    component: () => import('@/views/login/login'),
    hidden: true
  },
  // {
  //   path: '/reserveSystem',
  //   name: 'ReserveSystem',
  //   component: () => import('@/views/reserveSystem/reserveSystemLayout/reserveSystemLayout'),
  //   meta: { permissions: [], reserveSystem: true, keepAlive: true },
  //   redirect: '/reserveTabBar',
  //   children: [
  //     {
  //       path: '/reserveTabBar',
  //       component: () => import('@/views/reserveSystem/reserveTabBar/reserveTabBar'),
  //       name: 'ReserveTabBar',
  //       meta: { permissions: [], reserveSystem: true, keepAlive: true },
  //       redirect: '/initiateReserve',
  //       children: [
  //         {
  //           path: '/initiateReserve',
  //           component: () => import('@/views/reserveSystem/reserveTabBarPage/initiateReserve/initiateReserve'),
  //           name: 'InitiateReserve',
  //           meta: { permissions: [], reserveSystem: true }
  //         },
  //         {
  //           path: '/myReserve',
  //           component: () => import('@/views/reserveSystem/reserveTabBarPage/myReserve/myReserve'),
  //           name: 'MyReserve',
  //           meta: { permissions: [], reserveSystem: true, keepAlive: true }
  //         }
  //       ]
  //     },
  //     {
  //       path: '/reserveNavBar',
  //       component: () => import('@/views/reserveSystem/reserveNavBar/reserveNavBar'),
  //       name: 'ReserveNavBar',
  //       meta: { permissions: [], reserveSystem: true },
  //       children: [
  //         {
  //           path: '/searchPlace',
  //           component: () => import('@/views/reserveSystem/reserveNavBarPage/searchPlace/searchPlace'),
  //           name: 'SearchPlace',
  //           meta: { permissions: [], reserveSystem: true, title: '地址搜索' }
  //         },
  //         {
  //           path: '/selectDateTime',
  //           component: () => import('@/views/reserveSystem/reserveNavBarPage/selectDateTime/selectDateTime'),
  //           name: 'SelectDateTime',
  //           meta: { permissions: [], reserveSystem: true, title: '选择日期' }
  //         },
  //         {
  //           path: '/reserveForm',
  //           component: () => import('@/views/reserveSystem/reserveNavBarPage/reserveForm/reserveForm'),
  //           name: 'ReserveForm',
  //           meta: { permissions: [], reserveSystem: true, title: '预约信息' }
  //         },
  //         {
  //           path: '/reserveCardDetails',
  //           component: () => import('@/views/reserveSystem/reserveNavBarPage/reserveCardDetails/ReserveCardDetails'),
  //           name: 'ReserveCardDetails',
  //           meta: { permissions: [], reserveSystem: true, title: '预约详情' }
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    path: '*',
    component: () => import('@/views/404'),
    meta: {}
  }
]
// DONE: 动态路由, 需要判断权限 (或者不配置)
export const asyncRouterMap = [
  {
    path: '/',
    component: Tabbar,
    name: 'Tabbar',
    meta: { permissions: [] },
    redirect: '/index',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/home'),
        meta: { title: '首页', permissions: [], active: '@/assets/images/public/home-active.png', inactive: '@/assets/images/public/home.png' }
      },
      {
        path: '/index',
        name: 'HomepageScreen',
        component: () => import('@/views/index/index'),
        meta: { title: '智慧高投', permissions: [], active: '@/assets/images/public/index-active.png', inactive: '@/assets/images/public/index.png', menu: true }
      }, {
        path: '/personalCenter',
        name: 'PersonInfo',
        component: () => import('@/views/personalCenter/personalCenter'),
        meta: { title: '我的', permissions: [], active: '@/assets/images/public/personalCenter-active.png', inactive: '@/assets/images/public/personalCenter.png' }
      }
    ]
  },
  {
    path: '/aiot',
    component: Navbar,
    name: 'Navbar',
    meta: { permissions: [] },
    redirect: '/iotApp',
    children: [
      {
        path: '/propertyPlate',
        name: 'PropertyPlate',
        component: () => import('@/views/plate/plate'),
        meta: { showFooter: true, title: '物业看板', permissions: [], edit: false, menu: true }
      },
      {
        path: '/safetyCommitteePlate',
        name: 'SafetyCommitteePlate',
        component: () => import('@/views/plate/plate'),
        meta: { showFooter: true, title: '安委看板', permissions: [], edit: false, menu: true }
      },
      {
        path: '/video',
        name: 'WisdomVisual',
        component: () => import('@/views/video/video'),
        meta: { title: '智慧视觉', permissions: [], edit: false, menu: true, keepAlive: true }
      },
      {
        path: '/hazard',
        name: 'Statistics',
        component: () => import('@/views/hazard/hazard'),
        meta: { title: '隐患管理', permissions: [], edit: false, menu: true, keepAlive: true }
      },
      {
        path: '/iotApp',
        name: 'IotApp',
        component: () => import('@/views/aiot/iotApp'),
        meta: { title: '物联应用', permissions: [], edit: false, menu: true, keepAlive: true }
      },
      {
        path: '/placeResource',
        name: 'PlaceResource',
        component: () => import('@/views/placeResource/placeResource'),
        meta: { title: '网点管理', permissions: [], edit: false, menu: true }
      },
      {
        path: '/iotAppDetail',
        name: 'IotAppDetail',
        component: () => import('@/views/aiot/iotAppDetail'),
        meta: { title: '设备详情', permissions: [], edit: true }
      },

      {
        path: '/videoPlayer',
        name: 'VideoPlayer',
        component: () => import('@/views/videoPlayer/videoPlayer'),
        meta: { title: '视频播放', permissions: [], edit: false }
      },
      {
        name: 'PlaceResourceDetail',
        path: '/placeResourceDetail',
        component: () => import('@/views/placeResourceDetail/placeResourceDetail'),
        meta: { title: '网点详情', permissions: [], edit: true }
      },
      { path: '/hazardDetail',
        name: 'HazardDetail',
        component: () => import('@/views/hazardDetail/hazardDetail'),
        meta: { showFooter: true, title: '隐患详情', permissions: [], edit: true }
      },

      {
        path: '/editEquip',
        name: 'EditEquip',
        component: () => import('@/views/aiot/editEquip'),
        meta: { showFooter: true, title: '编辑设备', permissions: [], edit: false }
      },
      {
        path: '/unreadEvents',
        name: 'UnreadEvents',
        component: () => import('@/views/unreadEvents/unreadEvents'),
        meta: { showFooter: true, title: '未读事件列表', permissions: [] }
      },
      {
        path: '/bindEquip',
        name: 'BindEquip',
        component: () => import('@/views/bindEquip/bindEquip'),
        meta: { showFooter: true, title: '绑定设备', permissions: [] }
      },
      {
        path: '/placeResourceEditorial',
        name: 'PlaceResourceEditorial',
        component: () => import('@/views/placeResourceEditorial/placeResourceEditorial'),
        meta: { showFooter: true, title: '编辑网点', permissions: [], keepAlive: true }
      },
      {
        path: '/hazardEditorial',
        name: 'HazardEditorial',
        component: () => import('@/views/hazardEditorial/hazardEditorial'),
        meta: { showFooter: true, title: '编辑隐患', permissions: [] }
      },
      {
        path: '/placeResourceAddition',
        name: 'PlaceResourceAddition',
        component: () => import('@/views/placeResourceAddition/placeResourceAddition'),
        meta: { showFooter: true, title: '新增网点', permissions: [] }
      },
      {
        path: '/personalInfo',
        name: 'PersonalInfo',
        component: () => import('@/views/personalCenter/personalInfo'),
        meta: { showFooter: true, title: '个人信息', permissions: [] }
      },
      {
        path: '/userName',
        name: 'UserName',
        component: () => import('@/views/personalCenter/userName'),
        meta: { showFooter: true, title: '设置用户名', permissions: [] }
      },
      {
        path: '/addFireEquip',
        name: 'AddFireEquip',
        component: () => import('@/views/aiot/addFireEquip'),
        meta: { showFooter: true, title: '新增消防设备', permissions: [] }
      },
      {
        path: '/chargeSharing',
        name: 'ChargeSharing',
        component: () => import('@/views/applicationSubsystem/chargeSharing/chargeSharing'),
        meta: { showFooter: true, title: '共享充电', permissions: [], menu: true }
      },
      {
        path: '/chargeSharingOrderDetail',
        name: 'ChargeSharingOrderDetail',
        component: () => import('@/views/applicationSubsystem/chargeSharing/chargeSharingOrderDetail'),
        meta: { showFooter: true, title: '订单详情', permissions: [] }
      },
      {
        path: '/equipDetail',
        name: 'EquipDetail',
        component: () => import('@/views/applicationSubsystem/chargeSharing/equipDetail'),
        meta: { showFooter: true, title: '设备详情', permissions: [] }
      },
      {
        path: '/myWallet',
        name: 'MyWallet',
        component: () => import('@/views/personalCenter/myWallet'),
        meta: { showFooter: true, title: '我的钱包', permissions: [] }
      },
      {
        path: '/walletRecharge',
        name: 'WalletRecharge',
        component: () => import('@/views/personalCenter/walletRecharge'),
        meta: { showFooter: true, title: '充值', permissions: [] }
      },
      {
        path: '/walletWithdrawal',
        name: 'WalletWithdrawal',
        component: () => import('@/views/personalCenter/walletWithdrawal'),
        meta: { showFooter: true, title: '提现', permissions: [] }
      },
      {
        path: '/walletDetail',
        name: 'WalletDetail',
        component: () => import('@/views/personalCenter/walletDetail'),
        meta: { showFooter: true, title: '钱包明细', permissions: [] }
      },
      {
        path: '/incomeDetail',
        name: 'IncomeDetail',
        component: () => import('@/views/personalCenter/incomeDetail'),
        meta: { showFooter: true, title: '收入明细', permissions: [] }
      },
      {
        path: '/editLine',
        name: 'EditLine',
        component: () => import('@/views/wisdomAirSwitch/editLine.vue'),
        meta: { showFooter: true, title: '编辑线路', permissions: [] }
      },
      {
        path: '/thresholdDetail',
        name: 'ThresholdDetail',
        component: () => import('@/views/wisdomAirSwitch/thresholdDetail.vue'),
        meta: { showFooter: true, title: '报警阈值', permissions: [], edit: true }
      },
      {
        path: '/addPowerSupply',
        name: 'AddPowerSupply',
        component: () => import('@/views/wisdomAirSwitch/addPowerSupply.vue'),
        meta: { showFooter: true, title: '新增电源', permissions: [] }
      },
      {
        path: '/editThreshold',
        name: 'UpdateThreshold',
        component: () => import('@/views/wisdomAirSwitch/editThreshold.vue'),
        meta: { showFooter: true, title: '修改阈值', permissions: [] }
      },
      {
        path: '/airSwitch',
        name: 'AirSwitch',
        component: () => import('@/views/applicationSubsystem/airSwitch/airSwitch'),
        meta: { showFooter: true, title: '智能用电', permissions: [], menu: true, keepAlive: true }
      },
      {
        path: '/aboutEquip',
        name: 'AboutEquip',
        component: () => import('@/views/applicationSubsystem/airSwitch/aboutEquip'),
        meta: { showFooter: true, title: '关于设备', permissions: [] }
      },
      {
        path: '/realtimeData',
        name: 'RealtimeData',
        component: () => import('@/views/applicationSubsystem/airSwitch/realtimeData'),
        meta: { showFooter: true, title: '实时数据', permissions: [] }
      },
      {
        path: '/routeTiming',
        name: 'RouteTiming',
        component: () => import('@/views/applicationSubsystem/airSwitch/routeTiming'),
        meta: { showFooter: true, title: '路线定时配置', permissions: [], keepAlive: true }
      },
      {
        path: '/routeTimingDetails',
        name: 'RouteTimingDetails',
        component: () => import('@/views/applicationSubsystem/airSwitch/routeTimingDetails'),
        meta: { showFooter: true, title: '定时配置详情', permissions: [] }
      },
      {
        path: '/historyCurve',
        name: 'HistoryCurve',
        component: () => import('@/views/applicationSubsystem/airSwitch/historyCurve'),
        meta: { showFooter: true, title: '历史曲线', permissions: [] }
      },
      {
        path: '/brakeWarning',
        name: 'BrakeWarning',
        component: () => import('@/views/applicationSubsystem/airSwitch/brakeWarning'),
        meta: { showFooter: true, title: '分合闸警示', permissions: [] }
      },
      {
        path: '/selfCheckDetail',
        name: 'SelfCheckDetail',
        component: () => import('@/views/applicationSubsystem/airSwitch/selfCheckDetail'),
        meta: { showFooter: true, title: '漏保自检详情', permissions: [] }
      },
      {
        path: '/selfCheckLogs',
        name: 'SelfCheckLogs',
        component: () => import('@/views/applicationSubsystem/airSwitch/selfCheckLogs'),
        meta: { showFooter: true, title: '漏保自检日志', permissions: [] }
      },
      {
        path: '/addOrEditSelfCheckTask',
        name: 'AddOrEditSelfCheckTask',
        component: () => import('@/views/applicationSubsystem/airSwitch/addOrEditSelfCheckTask'),
        meta: { showFooter: true, title: '', permissions: [] }
      },
      {
        path: '/faceRecognition',
        name: 'FaceRecognition',
        component: () => import('@/views/applicationSubsystem/faceRecognition/faceRecognition'),
        meta: { showFooter: true, title: '人员识别', permissions: [], menu: true, keepAlive: true }
      },
      {
        path: '/identifyInformation',
        name: 'IdentifyInformation',
        component: () => import('@/views/applicationSubsystem/faceRecognition/identifyInformation'),
        meta: { showFooter: true, title: '识别信息', permissions: [], keepAlive: true }
      },
      {
        path: '/trajectoryByEquip',
        name: 'TrajectoryByEquip',
        component: () => import('@/views/applicationSubsystem/faceRecognition/trajectoryByEquip'),
        meta: { showFooter: true, title: '', permissions: [] }
      },
      {
        path: '/carIdentify',
        name: 'CarIdentify',
        component: () => import('@/views/applicationSubsystem/carIdentify/carIdentify'),
        meta: { showFooter: true, title: '车辆识别', permissions: [], menu: true, keepAlive: true }
      },
      {
        path: '/carIdentifyDetail',
        name: 'CarIdentifyDetail',
        component: () => import('@/views/applicationSubsystem/carIdentify/carIdentifyDetail'),
        meta: { showFooter: true, title: '车辆识别', permissions: [] }
      },
      {
        path: '/digitalSentry',
        name: 'DigitalSentry',
        component: () => import('@/views/applicationSubsystem/digitalSentry/digitalSentry'),
        meta: { showFooter: true, title: '哨兵管理', permissions: [], menu: true, keepAlive: true }
      },
      {
        path: '/monitorManage',
        name: 'MonitorManage',
        component: () => import('@/components/digitalSentry/sentryManage/monitorManage/MonitorManage'),
        meta: { showFooter: true, title: '监测管理', permissions: [] }
      },
      {
        path: '/passageManage',
        name: 'PassageManage',
        component: () => import('@/components/digitalSentry/sentryManage/passageManage/PassageManage'),
        meta: { showFooter: true, title: '通行管理', permissions: [] }
      },
      {
        path: '/passageRecordDetail',
        name: 'PassageRecordDetail',
        component: () => import('@/components/digitalSentry/passageRecord/PassageRecordDetail'),
        meta: { showFooter: true, title: '通行记录', permissions: [] }
      },
      {
        path: '/reserveManageDetail',
        name: 'ReserveManageDetail',
        component: () => import('@/components/digitalSentry/reserveManage/ReserveManageDetail'),
        meta: { showFooter: true, title: '预约管理', permissions: [] }
      },
      {
        path: '/reserveCheckLog',
        name: 'ReserveCheckLog',
        component: () => import('@/components/digitalSentry/reserveManage/ReserveCheckLog'),
        meta: { showFooter: true, title: '审核日志', permissions: [] }
      }
    ]
  },

  {
    path: '/login',
    component: () => import('@/views/login/login'),
    meta: {}
  }
]
// DONE: 使用 config.json影响动态路由的内容
// 读取 config.json中动态路由的名字
const routesName = new Set(Reflect.ownKeys(config.router.routes))
// 读取 config.json中动态路由中对应的权限对象
const routes = config.router.routes

// DONE: 检索路由是否在 config.json列表中
const isInList = (route) => {
  // 加载 config.json中拥有的路由

  // 404页面默认添加 (特殊页面)
  // if (route.name === '404') {
  //   return true
  // }

  // 判断是否在 config.json当中
  if (routesName.has(route.name) || route.meta.reserveSystem) {
    route.meta.permissions = route.meta.reserveSystem ? [] : Reflect.get(routes, route.name).permissions
    route.meta.title = route.meta.reserveSystem ? [] : Reflect.get(routes, route.name).desc
    return true
  } else {
    return false
  }
}

// DONE: 疯狂遍历所有路由
const child = (routes) => {
  // 循环路由数组
  routes.forEach((route, index) => {
    if (isInList(route)) {
      if (Reflect.has(route, 'children')) {
        child(route.children)
      }
      return
    } else {
      // 对不存在 config.json的路由进行删除
      routes.splice(index, 1)
    }
  })
}

// 根据 config.json修改权限
child(asyncRouterMap)
export default new VueRouter({
  mode: 'history', // 后端支持可开
  base: '/mobile',
  // scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
