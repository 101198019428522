var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout" },
    [
      _c(
        "van-overlay",
        {
          attrs: { show: _vm.show, "z-index": "99999999", "lock-scroll": true },
        },
        [
          _c(
            "div",
            {
              class: _vm.flashingBox,
              on: {
                click: function ($event) {
                  return _vm.toDetail(_vm.hazardMessage.id)
                },
              },
            },
            [
              _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "content-left" }, [
                  _c("div", { staticClass: "unread" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "icon-box" }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: {
                        src:
                          _vm.hazardMessage.pictureUrl &&
                          _vm.hazardMessage.pictureUrl !== null
                            ? _vm.eventPicturePrefix +
                              _vm.hazardMessage.pictureUrl
                            : require("@/assets/images/public/warning.png"),
                        alt: "",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "content-right" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.hazardMessage.equipAddress) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "hazard-type" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.hazardMessage.hazardTypeName) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.changeDate(_vm.hazardMessage.createdTime / 1000)
                        ) +
                        "\n          "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "foot" }, [
                _c(
                  "div",
                  {
                    class:
                      _vm.hazardMessage.type !== "1" &&
                      _vm.hazardMessage.type !== "4" &&
                      _vm.hazardMessage.type !== "2"
                        ? "multiple"
                        : "close",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.closeMessage.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n          关闭\n        ")]
                ),
                _vm._v(" "),
                _vm.muteStatus
                  ? _c(
                      "div",
                      {
                        staticClass: "mute",
                        style: _vm.haveTheMute
                          ? "color:#FF1743;"
                          : "color:#6F85A2;",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.equipMute(_vm.hazardMessage.equipId)
                          },
                        },
                      },
                      [_vm._v("\n          消音\n        ")]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.currentAirSwitchMessage && _vm.isAirSwitchPath
        ? _c(
            "van-notify",
            {
              attrs: {
                background: _vm.currentAirSwitchMessage.airSwitchBackground,
              },
              on: {
                click: function ($event) {
                  return _vm.handleMessageClick(_vm.currentAirSwitchMessage)
                },
              },
              model: {
                value: _vm.showAirSwitchMessage,
                callback: function ($$v) {
                  _vm.showAirSwitchMessage = $$v
                },
                expression: "showAirSwitchMessage",
              },
            },
            [
              _c("div", { staticClass: "message-container" }, [
                _c("div", { staticClass: "left" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.currentAirSwitchMessage.leftIconImg,
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "center" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.currentAirSwitchMessage.title) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "time-and-address" }, [
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.currentAirSwitchMessage.time)),
                    ]),
                    _c("span", { staticClass: "address" }, [
                      _vm._v(_vm._s(_vm.currentAirSwitchMessage.address)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.currentAirSwitchMessage.lines
                    ? _c("div", { staticClass: "lines" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.currentAirSwitchMessage.lines) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("van-icon", {
                      attrs: { name: "cross" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.closeAirSwitchMessage.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("van-dialog", {
        staticClass: "layout-air-switch-dialog",
        attrs: {
          title: "您编辑内容未保存，是否确定跳转页面？",
          "show-cancel-button": "",
        },
        on: {
          confirm: _vm.confirmAirSwitchJump,
          cancel: function ($event) {
            _vm.showAirSwitchMessageJump = false
          },
        },
        model: {
          value: _vm.showAirSwitchMessageJump,
          callback: function ($$v) {
            _vm.showAirSwitchMessageJump = $$v
          },
          expression: "showAirSwitchMessageJump",
        },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }