import request from '@/utils/request'

class User {
/**
 * 用户账号密码登录接口
 * @param {*} data data参数
 * @returns
 */
  async postUserLogin(data) {
    return await request({
      url: '/apis/login',
      method: 'post',
      data
    })
  }
  /**
   * 获取验证码接口
   * @param {*} params phone
   * @returns
   */
  async getPhoneCode(params) {
    return await request({
      url: `/apis/login/code`,
      method: 'get',
      params
    })
  }
  //  用户验证码登录接口
  /**
   *
   * @param {*} phone 手机
   * @param {*} code 验证码
   * @param {*} params 登录端：移动2
   * @returns
   */
  async getUserLoginByCode(phone, code, params) {
    return await request({
      url: `/apis/login/phone/${phone}/code/${code}`,
      method: 'get',
      params
    })
  }
  /**
   * 获取个人信息 （用token识别id)
   * @returns
   */
  async personInfo() {
    return await request({
      url: `/apis/user/info`,
      method: 'get'
    })
  }
  /**
   * 金额提现计算费率
   * @param {*} data 需要提现的金额
   * @returns
   */
  async billRate(data) {
    return await request({
      url: `/mockserver/user/bill/withdraw/calculate/rate`,
      method: 'post',
      data
    })
  }
  /**
   * 获取提现验证码
   * @returns
   */
  async withdrawalCode() {
    return await request({
      url: `/mockserver/user/bill/withdraw/code`,
      method: 'get'
    })
  }
  /**
   * 金额提现
   * @param {*} data 验证码、提现总金额
   * @returns
   */
  async withdrawMoney(data) {
    return await request({
      url: `/mockserver/user/bill/withdraw`,
      method: 'post',
      data
    })
  }
  /**
   * 修改个人信息
   * @returns
   */
  async alterPersonInfo(data) {
    return await request({
      url: `/apis/user/info`,
      method: 'put',
      data
    })
  }
  /**
   * 获取用户拥有对应的权限列表 （用token识别id）
   * @returns
   */
  async permissionList() {
    return await request({
      url: `/apis/permission/list`,
      method: 'get'
    })
  }
  /**
   * 设置用户的故障推送设置
   * @param {userId} params
   * @param {*} paradatam 修改信息
   * @returns
   */
  async setErrorSetting(params, data) {
    return await request({
      url: `/apis/user/error/setting`,
      method: 'put',
      params,
      data
    })
  }

  /**
   * 设置用户的事件推送设置
   * @param {userId} params
   * @param {*} data 修改信息
   * @returns
   */
  async setEventSetting(params, data) {
    return await request({
      url: `/apis/user/event/setting`,
      method: 'put',
      params,
      data
    })
  }
  /**
   * 获取用户的故障推送设置
   * @param {*} params 子账号id
   * @returns
   */
  async getErrorSetting(params) {
    return await request({
      url: `/apis/user/error/setting`,
      method: 'get',
      params
    })
  }
  /**
   * 获取用户的事件推送设置
   * @param {*} params 子账号id
   * @returns
   */
  async getEventSetting(params) {
    return await request({
      url: `/apis/user/event/setting`,
      method: 'get',
      params
    })
  }
  /**
   * 账单列表-提现明细
   * @param {*} page 页数
   * @param {*} size 行数
   * @param {*} params 开始时间、结束时间
   * @returns
   */
  async getWithdrawList(page, size, params) {
    return await request({
      url: `/apis/user/bill/withdraw/list/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }

  /**
   * 账单列表-收入明细
   * @param {*} page 页数
   * @param {*} size 行数
   * @param {*} params 开始时间、结束时间
   * @returns
   */
  async getIncomeList(page, size, params) {
    return await request({
      url: `/apis/user/bill/income/list/page/${page}/size/${size}`,
      method: 'get',
      params
    })
  }

  /**
   * 账单类型
   * @returns
   */
  async billTypeList() {
    return await request({
      url: `/apis/user/bill/type/list`,
      method: 'get'
    })
  }
}
export default new User()
